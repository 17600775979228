export type TermCondition = {
    code: string;
    term: string;
};

export type AcceptedTermCondition = TermCondition & {
    accepted: boolean;
};

export type UserAcceptedTermsCondition = {
    dateAccepted: Date;
    acceptedTerms: AcceptedTermCondition[];
};

export const termsAndConditions: TermCondition[] = [{
        code: 'T001',
        term: 'The software is provided "as is" without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall the authors or copyright holders be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software or the use or other dealings in the software.'
    },{
        code: 'T002',
        term: 'Data is provided by the Open Data https://opendata.transport.nsw.gov.au/'
    },{
        code: 'T003',
        term: 'The data in Open Data is provided by outsource systems, which is outside the control of TfNSW (Transport for NSW).'
    },{
        code: 'T004',
        term: 'The occupancy data is an estimated count of vehicle in the car park as there are various reasons that may affect the count.'
    },{
        code: 'T005',
        term: 'Data may be reset at any time to handle errors caused by power outages, broken boom gates or any other issues that might cause operations issues.'
    },{
        code: 'T999',
        term: 'You can only use the software if you have accepted all these terms and conditions.'
    }
];

export const hasUserAcceptedAllTermsAndConditions = (): boolean => {
    const userAcceptedTermsValue = localStorage.getItem("userAcceptedTerms");
    let userAcceptedTerms = {} as UserAcceptedTermsCondition;
    if (userAcceptedTermsValue != null) {
        userAcceptedTerms = JSON.parse(userAcceptedTermsValue);
    }

    const userAcceptedTermCodes = userAcceptedTerms.acceptedTerms != null ? userAcceptedTerms.acceptedTerms.map(c => c.code) : [];
    const termsCodes = termsAndConditions.map(c => c.code);
    const termsAccepted = termsCodes.filter((code) => userAcceptedTermCodes.findIndex(c => c === code) > -1);
    return termsAccepted.length === termsCodes.length;
};

export const getTermsAndConditionsToAccept = (): AcceptedTermCondition[] => {
    return termsAndConditions.map(termAndCondition => { return <AcceptedTermCondition>{...termAndCondition, accepted: false} });
};

export const userHasAcceptedAllTermsAndConditions = (userAcceptedTermsCondition: UserAcceptedTermsCondition) => {
    localStorage.setItem("userAcceptedTerms", JSON.stringify(userAcceptedTermsCondition));
};