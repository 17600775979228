import { useState, createContext, useEffect, PropsWithChildren } from "react";

import {
  getCarParkList,
  getFacilityInfo,
  Facility,
  carParkTransform,
  FacilityInfo,
  getAnnouncements,
} from "./carpark.service";
import CurrentScreenSize, { ScreenSizeType } from "../utility/CurrentScreenSize";

export type CarParkContextType = {
  carParkLocations: Facility[];
  selectedCarPark: FacilityInfo | null;
  getCarParkInfo: (facilityId: string) => Promise<FacilityInfo | null>;
  toggleMenu: (isOpen: boolean) => void;
  isMenuOpen: boolean;
  isLoading: boolean;
  error: string | null;
  listItemClicked: () => void;
  listItemClickCount: number;
  screenSize: ScreenSizeType;
  message: string;
};
export const CarParkContext = createContext<CarParkContextType>({} as CarParkContextType);
type CarParkProps = {};

export const CarParkContextProvider = (props: PropsWithChildren<CarParkProps>) => {
  const [carParkLocations, setCarParkLocations] = useState<Facility[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedCarPark, setSelectedCarPark] = useState<FacilityInfo | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [listItemClickCount, setListItemClickCount] = useState(0);
  const [message, setMessage] = useState("");
  const screenSize = CurrentScreenSize();

  const retrieveCarParks = () => {
    setIsLoading(true);
    setCarParkLocations([]);

    getCarParkList()
      .then(carParkTransform)
      .then((results) => {
        setError(null);
        setIsLoading(false);
        setCarParkLocations(results);
      })
      .catch((err: Error) => {
        console.log(err);
        console.log(typeof(err));
        setCarParkLocations([]);
        setIsLoading(false);
        setError('Problem retrieving car park list. Try again later.');
      });
  };

  const retrieveAnnouncements = () => {
    getAnnouncements()
      .then((result) => {
        setMessage(result);
      });
  };

  useEffect(() => {
    retrieveCarParks();
    retrieveAnnouncements();
  }, []);

  const getCarParkInfo = async(facilityId: string) => {
    try {
      const carParkInfo = await getFacilityInfo(facilityId);
      setSelectedCarPark(carParkInfo);
      setError(null);
      return carParkInfo;
    } catch (err) {
      console.log(err);
      setError('Problem retrieving the information. Try again later.');
      return null;
    }
  };

  const toggleMenu = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  const listItemClicked = () => {
    setListItemClickCount(listItemClickCount + 1);
  };

  return (
    <CarParkContext.Provider
      value={{
        carParkLocations,
        getCarParkInfo,
        selectedCarPark,
        toggleMenu,
        isMenuOpen,
        isLoading,
        error,
        listItemClicked,
        listItemClickCount,
        screenSize,
        message
      }}
    >
      {props.children}
    </CarParkContext.Provider>
  );
};
