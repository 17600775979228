import { useContext } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { CarParkContext } from '../services/carpark.context';
import './AboutList.css';
import { Coffee, Email, QuestionAnswer, Wysiwyg } from '@mui/icons-material';

const AboutList = () => {  
  const { isMenuOpen } = useContext(CarParkContext);

  let headingBackgroundColor = '#CCC2AF';

  return (
    <List 
      subheader={<li/>}>
      <ListSubheader sx={{ display: 'block', padding: 0, backgroundColor: {backgroundColor: headingBackgroundColor}, color: 'white' }}>
        <ListItem
          sx={{
            minHeight: 48,
            justifyContent: isMenuOpen ? 'initial' : 'center',
            px: 2.5,
          }}
          key="Others"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMenuOpen ? 3 : 'auto',
              justifyContent: 'center',
              display: isMenuOpen ? 'none' : 'box',
            }}
          >
            <Wysiwyg htmlColor="white" />
          </ListItemIcon>
          <ListItemText primary="Other Information" sx={{ opacity: isMenuOpen ? 1 : 0, justifyContent: 'left' }} />
        </ListItem>
      </ListSubheader>
      <ListItem key="buycoffee" disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isMenuOpen ? 'initial' : 'center',
            px: 2.5,
          }}
          key="buycoffee"
          href="https://www.buymeacoffee.com/d2dapps"
          target="_blank"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMenuOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <Coffee />
          </ListItemIcon>
          <ListItemText primary="Buy me a coffee" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      <ListItem key="feedback" disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isMenuOpen ? 'initial' : 'center',
            px: 2.5,
          }}
          key="feedback"
          href="https://forms.gle/yh5XqMfe5mQAs8WZ7"
          target="_blank"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMenuOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <QuestionAnswer />
          </ListItemIcon>
          <ListItemText primary="Feedback" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      <ListItem key="contactus" disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isMenuOpen ? 'initial' : 'center',
            px: 2.5,
          }}
          key="contactus"
          href="mailto:sydneycommuterparking@gmail.com"
          target="_blank"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMenuOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <Email />
          </ListItemIcon>
          <ListItemText primary="Contact Us" sx={{ opacity: isMenuOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default AboutList;