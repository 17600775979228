import React, { useContext } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import DirectionsSubwayFilledIcon from '@mui/icons-material/DirectionsSubwayFilled';
import { CarParkContext } from '../services/carpark.context';
import './FacilityList.css';
import { FacilityType } from '../services/carpark.service';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

type Props = {
  showType: FacilityType;
};

const FacilityList = ({showType}: Props) => {  
  const { carParkLocations, getCarParkInfo, isMenuOpen, listItemClicked } = useContext(CarParkContext);

  const handleListItemClick = async(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    carParkId: string,
  ) => {
    await getCarParkInfo(carParkId);
    listItemClicked();
  };

  let headingBackgroundColor = '#EE780C';
  if (showType === 'Metro') {
    headingBackgroundColor = '#009ED7';
  } else if (showType === 'Bus') {
    headingBackgroundColor = '#98D6FC';
  }

  return (
    <List 
      subheader={<li/>}>
      <ListSubheader sx={{ display: 'block', padding: 0, backgroundColor: {backgroundColor: headingBackgroundColor}, color: 'white' }} key={`MetroSubHeader${showType}`}>
        <ListItem
          sx={{
            minHeight: 48,
            justifyContent: isMenuOpen ? 'initial' : 'center',
            px: 2.5,
          }}
          key={`MetroButton${showType}`}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMenuOpen ? 3 : 'auto',
              justifyContent: 'center',
              display: isMenuOpen ? 'none' : 'box',
            }}
          >
            {showType === "Metro" ? <DirectionsSubwayFilledIcon htmlColor="white" /> : (showType === "Bus" ? <DirectionsBusIcon htmlColor="white" /> : <TrainIcon htmlColor="white" />)}
          </ListItemIcon>
          <ListItemText primary={showType} sx={{ opacity: isMenuOpen ? 1 : 0, justifyContent: 'left' }} />
        </ListItem>
      </ListSubheader>
      {carParkLocations
        .filter((facility) => {
          return facility.type === showType;
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((carpark, index) => 
        <ListItem key={carpark.id} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            key={index}
            onClick={(event) => handleListItemClick(event, carpark.id)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenuOpen ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {showType === "Metro" ? <DirectionsSubwayFilledIcon /> : (showType === "Bus" ? <DirectionsBusIcon /> : <TrainIcon />)}
            </ListItemIcon>
            <ListItemText primary={carpark.name} sx={{ opacity: isMenuOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
};

export default FacilityList;