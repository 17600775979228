import './App.css';
import { CarParkContextProvider } from './services/carpark.context';
import TermsAndConditions from './components/TermsAndConditions';

function App() {
  return (
    <CarParkContextProvider>
      <TermsAndConditions/>
    </CarParkContextProvider>
  );
}

export default App;
